<template>
    <div class="committee-item" :style="(width ? 'width:'+width : '')">
        <div class="committee-item-image flex x-center y-center" @click="orgJumpPage(item.id,type)">
            <img :src="item.index_logo | urlFilter(250)" :alt="item.name">
        </div>
        <div class="row-1 fs14 color333 pt10 pr10 pl10">{{item.name}}</div>
        <div class="mt5 fs12 color999 flex" v-if="item.fans_count">
            <span>{{item.fans_count}}人关注</span>
            <span class="med" :class="[item.fans_status == 1 ? 'med-aixin_shixin' : 'med-aixin']" @click="followCommittee"></span>
        </div>
    </div>
</template>

<script>
import storage from 'store'
export default {
    name: "committee-item",
    props: {
        type: {
            type: String,
            default: '',
        },
        width: {
            type: String,
            default: '',
        },
        height: {
            type: [Number, String],
            default: 0,
        },
        item: {
            type: Object,
            defalut: () => {
                //   id:1,
                //   img:'',
                //   title:',
                //   url:'',  
            },
        },
    },
    methods: {
        orgJumpPage(committee_id,type) {
            if(type == 'ma'){
                this.$router.push({ 
                    path: '/special-ma/org-detail/'+this.$route.params.id,
                    query: {
                        id:committee_id
                    }
                });
            }else{
                this.request.post('CommitteeDetail', { committee_id: committee_id }).then(res => {
                    let orgContent = res.data.committee
                    storage.set('orgLogoName',JSON.stringify(orgContent))
                    let routeData = this.$router.resolve({ 
                        path: '/organization/org-homepage',
                        query: {
                            committee_id
                        }
                    });
                    window.open(routeData.href, '_blank');
                })
            }
            
        },
        followCommittee(){
            this.request.post('FollowCommittee',{committee_id:this.item.id}).then(res=>{
                let { fans_status,fans_count } = this.item
                if(fans_status){
                    fans_count = parseInt(fans_count) - 1;
                }else{
                    fans_count = parseInt(fans_count) + 1;
                }
                this.item.fans_status = !fans_status
                this.item.fans_count = fans_count
            })
        }
    }
};
</script>

<style scoped lang="less">
.committee-item {
    width: 180px;
    flex-shrink: 0;
    display: block;
    margin-right: 20px;
    cursor: pointer;

    &-image {
        height: 88px;
        background: #F8F9FB;
        border-radius: 5px;

        img {
            max-width: 55px;
            max-height: 55px;
        }
    }

    .med-aixin_shixin {
        color: #FF8525;
    }
}
@media screen and (max-width:768px){
    .committee-item{
        padding: 0 0 10px;
    }
}
</style>
