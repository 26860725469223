<template>
    <layout class="wrapper">
        <div class="container index-container flex mt30">
            <a-spin :spinning="loading" tip="Loading..." class="home-left">
                <div class="" v-show="!loading">
                    <!-- 一层 -->
                    <div class="flex home-left-floor1">
                        <a-carousel class="carousel" style="overflow: hidden" arrows autoplay :autoplay-speed="4000">
                            <!--autoplay-->
                            <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
                                <a-icon type="left-circle" />
                            </div>
                            <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                                <a-icon type="right-circle" />
                            </div>
                            <img :src="item.img | urlFilter(360)" alt="" v-for="(item, index) in bannerList" @touchstart="handleBanerToPage(item)" @click="handleBanerToPage(item)" :key="index">
                        </a-carousel>
                        <div v-if="informationList.length" class="hlf-news ml20 flex-item t-l">
                            <common-title path="/infomation-list?index=115" title="会议动态"></common-title>
                            <div class="meeting-list" style="height: 180px;overflow:hidden;">
                                <router-link :to="{ path: '/infomation-detail', query: { id: informationList[0].id, type: informationList[0].type } }" style="line-height:33px;" class="strong fs20 color333 row-2" :title="informationList[0].news_title">
                                    {{ informationList[0].news_title }}
                                </router-link>
                                <router-link v-for="(item, index) in informationList.slice(1, 4)" :key="index" class="hlf-news-item mt5 color333 fs16 row-2" :to="{ path: '/infomation-detail', query: { id: item.id, type: item.type } }" :title="item.news_title">
                                    {{ item.news_title }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- 二层 -->
                    <div class="mt30">
                        <common-title path="/expert/index" title="关注感兴趣的专家"></common-title>
                        <div class="expert-list">
                            <div :class="['scroll-left',{ 'disabled': current == 0 }]" @click="handlePrevEvent" class="btn-direction med med-left"></div>
                            <div class="flex swiper-content" :style="{ transform: 'translateX(' + (-263 * current) + 'px)' }">
                                <expert-item v-for="(item, index) in chairList" :key="index" :item="item"></expert-item>
                            </div>
                            <div :class="['scroll-left',{ 'disabled': current == chairList.length - 3 }]" @click="handleNextEvent" class="btn-direction med med-right"></div>
                        </div>
                    </div>
                    <!-- 三层 -->
                    <div class="flex mt30 home-left-floor3">
                        <div class="flex-item">
                            <common-title path="/infomation-list?index=116" title="学术资讯"></common-title>
                            <template v-for="(item, index) in zixunList">
                                <text-item :key="index" gap="10" path="/infomation-detail" type="1" :item="item" :showDesc="false" source="index"></text-item>
                            </template>
                        </div>
                        <div class="ml30 flex-item">
                            <common-title path="/infomation-list?index=6" title="大咖动态"></common-title>
                            <template v-for="(item, index) in bigNewsList">
                                <text-item :key="index" gap="10" path="/infomation-detail" type="1" :item="item" :showDesc="false" source="index"></text-item>
                            </template>
                        </div>
                    </div>
                    <!-- 四层 -->
                    <div class="flex mt30 home-left-floor4">
                     <div class="flex-item">
                            <common-title path="/guide-list" title="临床指南下载"></common-title>
                            <template v-for="(item, index) in guideList">
                                <text-item :key="index" path="/guide-list-detail" gap="10" type="1" :item="item" source="index"></text-item>
                            </template>
                        </div>
                        <div class="ml30 flex-item">
                            <common-title path="/infomation-list?index=3" title="招聘信息"></common-title>
                            <div class="flex x-between" v-if="recruitmentList.length">
                                <div class="flex-item">
                                    <template v-for="(item, index) in recruitmentList">
                                        <text-item :key="index" gap="10" path="/infomation-detail" type="1" :item="item" :showDesc="false" source="index"></text-item>
                                    </template>
                                </div>
                                <!-- <div class="flex-item ml30 recruitment-item">
                                    <template v-for="(item, index) in recruitmentList.slice(4, 8)">
                                        <text-item :key="index" gap="10" path="/infomation-detail" type="1" :item="item" :showDesc="false" source="index"></text-item>
                                    </template>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- 五层 -->
                    <div class="mt30">
                        <common-title path="/organization/index" title="他们都在使用会务通"></common-title>
                        <committee-list :list="committeeList"></committee-list>
                    </div>
                </div>
            </a-spin>
            <div class="home-right t-l">
                <div class="flex y-center">
                    <router-link to="/database/advanced-search" class="home-advertising mb25">
                        <img src="~@/assets/images/paper-enter-half.jpg" alt="">
                    </router-link>
                    <router-link to="/awards/index" class="home-advertising mb25">
                        <img src="~@/assets/images/award-entry.jpg" alt="">
                    </router-link>
                </div>
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index';
import layoutRight from '@/components/layout/layout-right';
import commonTitle from '@/components/common-title';
import textItem from '@/components/text-item';
import committeeList from '@/components/committee-list';
import expertItem from '@/components/expert-item';
import { jsonFormat } from '@/utils/jsonFormat.js';
import { handleBanerToPage } from '@/utils/jumpPageMethods';
import storage from 'store'
export default {
    name: 'Index',
    components: {
        layout,
        commonTitle,
        textItem,
        committeeList,
        expertItem,
        layoutRight
    },
    data() {
        return {
            handleBanerToPage,
            loading: true,
            current: 0,
            bannerList: [],  //banner列表
            informationList: [], //资讯列表
            chairList: [], //感兴趣的专家列表
            zixunList:[],  // 学术咨询列表
            bigNewsList: [], //大咖动态列表
            guideList: [], //临床指南列表
            recruitmentList: [], //招聘信息列表
            committeeList: [], //他们都在使用会务通
            layoutRightConfig: {
                // 会议
                meeting: {
                    show: true, // 是否显示
                    title: '本月会议', // 标题
                    methods: 'get',
                    moreLink: '/meeting-list?year=' + (new Date()).getFullYear() + '&month=' + ((new Date()).getMonth() + 1),
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 3,
                        is_now_year_meeting: 1,
                        subject_ids: storage.get('subjectIds') || ''
                    },   // 参数条件
                },
                // 直播
                live: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '本月直播', // 标题
                    moreLink: '/live-list?month=' + ((new Date()).getMonth() + 1),
                    data: {
                        page: 1,
                        limit: 2
                    },   // 参数条件
                },
                // 最新回放
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '最新回放', // 标题
                    data: {
                        page: 1,
                        PageSize: 4
                    },   // 参数条件
                },
                // 推荐期刊
                periodicalRank: {
                    show: true, // 是否显示
                    methods: 'post',
                    title: '推荐期刊', // 标题
                    data: {
                        limit: 2,
                        page: 1,
                        operate_id: storage.get('operate_id') || '',
                    },   // 参数条件
                }
            }
        }
    },
    mounted() {
        let data = {
            type: 6,
            page: 1,
            limit: 6,
            is_expert: 1,//大咖动态必传
            is_ykx_index: 1
        }
        let guideData = {
            limit: 6,
            operate_id: "",
            page: 1,
        }
        let reqArr = [
            // banner
            this.request.post('GetBanner'),
            // 感兴趣的专家
            this.request.post('GuessLikeExperts', { limit: 8 }),
            // 会议动态
            this.request.post('GetNews', { limit: 5, type: '115', page: 1 }),
            // 大咖动态
            this.request.post('BigGetNews', data),
            // 临床指南下载
            this.request.post('GuideList', guideData),
            // 招聘信息
            this.request.post('GetNews', { limit: 6, type: '3', page: 1 }),
            // 客户
            this.request.post('CommitteeList') ,
            // 学术资讯
            this.request.post('GetNews',  {type: 116,page: 1,limit: 6}),
        ]
        Promise.all(reqArr).then(res => {
            this.bannerList = res[0].data
            this.chairList = res[1].data
            this.informationList = res[2].data.list
            this.bigNewsList = res[3].data.list
            let guideObj = {
                news_title: 'title_cn',
                id: 'guide_id'
            }
            this.guideList = jsonFormat(res[4].data.list, guideObj)

            this.recruitmentList = res[5].data.list

            this.committeeList = res[6].data

            this.zixunList = res[7].data.list
            this.loading = false
        }).catch((err) => { console.log(err) })
    },
    methods: {
        handlePrevEvent() {
            if (this.current > 0) {
                --this.current;
            }
        },
        handleNextEvent() {
            if (this.chairList.length - 3 > this.current) {
                ++this.current;
            }
        }
    }
}
</script>
<style lang="less" scope>
.home-left {
    width: calc(66.66% - 30px);
    margin-right: 30px;

    .carousel {
        width: 400px;
        height: 225px;
    }
    .ant-carousel /deep/.custom-slick-arrow {
        width: 25px;
        height: 25px;
        font-size: 25px;
        color: #fff;
        background-color: rgba(31, 45, 61, 0.11);
        border-radius: 50%;
        opacity: 0.3;
    }
    .ant-carousel /deep/.custom-slick-arrow:before {
        display: none;
    }
    .ant-carousel /deep/.custom-slick-arrow:hover {
        opacity: 1;
    }

    .hlf-news {
        height: 225px;
        // overflow: hidden;
    }
}

.home-right {
    width: 33.33%;
    .home-advertising{
        width: 48%;
        display: block;
        border-radius: 4px;
        overflow: hidden;
        img{
            width: 100%;
            height: auto;
        }
    }

    // 院士风采
    .personal-item {
        background: #f9f9f9;
        border-radius: 100px;
        height: 100px;
        padding-right: 30px;

        &-image {
            width: 100px;
            border-radius: 100px;
            overflow: hidden;
        }
    }

    .zhanpin-list {
        /deep/.item-right-info {
            padding-left: 0;
        }
    }
}

.expert-list {
    position: relative;
    overflow: hidden;

    .swiper-content {
        flex-wrap: nowrap;
        transition: all 0.15s linear;
    }

    .btn-direction {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: 30px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        margin-top: -22px;
        background-color: @theme;
        color: #fff;
        border-radius: 30px;
        font-size: 16px;
        z-index: 2;

        &.disabled {
            opacity: 0.3;
        }

        &.med-left {
            left: 0;
        }

        &.med-right {
            right: 0;
        }
    }
}
@media screen and (max-width: 768px) {
    .container {
        display: block;
        padding: 0 10px;
    }
    .index-container {
        margin-top: 0;
        .carousel {
            margin-left: -10px;
            width: calc(100% + 20px);
            height: auto;
        }
    }
    .home-left {
        width: 100%;
        margin-right: 0;
        &-floor1 {
            display: block;
            .hlf-news {
                margin-top: 30px;
                margin-left: 0 !important;
                margin-bottom: 50px;
                height: auto;
                .meeting-list {
                    height: auto !important;
                }
            }
        }
        .expert-list {
            .scroll-left {
                display: none;
            }
            .swiper-content {
                overflow-x: scroll;
            }
        }
        &-floor3 {
            display: block;
            .guide-list {
                margin-left: 0;
                margin-top: 30px;
            }
            .ml30{
                margin-left:0;
            }
        }
        &-floor4 {
            display: block;
            .recruitment-item {
                display: none;
            }
            .ml30{
                margin-left:0;
            }
        }
    }
    .home-right {
        width: 100%;
        margin-top: 60px;
    }
}
</style>
