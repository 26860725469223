<template>
    <div class="component_box">
        <div class="item-box flex x-left y-center">
            <div :class="['item', 'mb20' , (index + 1) % 3 == 0 ? '' : 'mr20' ]" @click="expertJumpDetail(item.user_id)">
                <!-- 头像 -->
                <div class="head-portrait">
                    <img :src="item.avatar | urlFilter(250,'avatar') || require('@/assets/images/person-default.png')" alt="">
                </div>
                <!-- 关注 -->
                <!-- <div class="attention-btn t-c colorfff" v-if="item.fans_status == 1">已关注</div>
                <div class="attention-btn t-c colorfff isattention" v-else>关注</div> -->
                <div class="card">
                    <!--  正面   -->
                    <div class="front">
                        <div class="name-tag flex x-center y-bottom">
                            <span class="name strong fs18">{{item.real_name}}</span>
                            <span class="subject ml5 fs14 row-1">{{item.department}}</span>
                        </div>
                        <div class="company fs16 row-1">
                            {{item.org_cnname}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {  expertJumpDetail } from '@/utils/jumpPageMethods'
    export default {
        name: "expert-item",
        props : {
            index: {
                type: String,
                default: '',
            },
            item: {
                type: Object,
                defalut: () => {
                    //   name:1,
                    //   is_attention:'',
                },
            },
        },
        data(){
            return {

            }
        },
        methods:{
            expertJumpDetail
        }
    }
</script>

<style lang="less" scoped>
    .component_box{
        width: 100%;
        .item-box{
            flex-wrap: wrap;
            .item{
                height: 180px;
                width: 243px;
                position: relative;
                cursor: pointer;
                .head-portrait{
                    width: 88px;
                    height: 88px;
                    border-radius: 50px;
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 10;
                    background: url("~@/assets/images/person-default.png") no-repeat;
                    background-size: cover;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: top;
                    }
                }
                .attention-btn{
                    width: 48px;
                    height: 20px;
                    line-height: 20px;
                    border-radius: 10px;
                    font-size: 11px;
                    background: #cccccc;
                    position: absolute;
                    top: 76px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 10;
                }
                .isattention{
                    background: linear-gradient(to right,#5F96E6,#1E51C9);
                    box-shadow: 0 1px 2px 0 rgba(30, 81, 201, 0.1500);
                }
                .card{
                    width: 100%;
                    height: 136px;
                    background: #F9F9F9;
                    border-radius: 10px;
                    padding: 60px 23px 0;
                    box-sizing: border-box;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: all 1s;
                    overflow: hidden;
                    .front{
                        .name-tag{
                            .name{
                                color: @title;
                            }
                            .subject{
                                max-width: 130px;
                                color: @desc;
                            }
                        }
                        .company{
                            color: #666666;
                            text-align: center;
                        }
                    }
                }
                .contrary{
                    opacity: 0;
                    font-size: 12px;
                    color: #ffffff;
                    line-height: 16px;
                    .more{
                        font-size: 10px;
                    }
                }
            }
        }
    }

    @media screen and (max-width:768px){
        .component_box{
            .item-box .item {
                height:140px;
                width:240px;
                .head-portrait{
                    width:60px;
                    height:60px;
                }
                .card{
                    padding-top:30px;
                    height:100px;
                }
            }
        }
    }
</style>