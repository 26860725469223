<template>
    <div class="layout flex flex-column">
        <layout-header v-on="$listeners"></layout-header>
        <layout-nav class="nav-show"></layout-nav>
        <div class="flex-item">
            <slot></slot>
        </div>
        <layout-footer></layout-footer>
    </div>
</template>

<script>
import layoutHeader from '@/components/layout/layout-header.vue';
import layoutNav from '@/components/layout/layout-nav.vue';
import layoutFooter from '@/components/layout/layout-footer.vue';
export default {
    name: 'layout',
    components: {
        layoutHeader,
        layoutNav,
        layoutFooter
    },
    mounted() {

    },
}
</script>
<style lang="less" scoped>
.layout{
    min-height:100vh;
}
@media screen and (max-width:768px){
    .layout{
        .nav-show{
            display: none;
        }
    }
}
</style>
