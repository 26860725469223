<template>
    <div class="committee-list" >
        <div :class="['scroll-left',{'disabled':current == 0}]" @click="handlePrevEvent" class="btn-direction med med-left"></div>
        <div class="flex swiper-content" :style="{transform:'translateX('+(-197*current)+'px)'}">
            <committee-item width="177px" v-for="(item,index) in list" :key="index" :item="item"></committee-item>
        </div>
        <div :class="['scroll-left',{'disabled':current == list.length - 4}]" @click="handleNextEvent" class="btn-direction med med-right"></div>
    </div>
</template>

<script>
import committeeItem from '@/components/committee-item';
export default {
    name: "committee-list",
    components:{
        committeeItem
    },
    props: {
       list:{
           type:Array,
           defalut:() => [
            //    {
            //        id:1,
            //        title:'',
            //        img:'',
            //        num:0,
            //        is_collection:1,
            //    }
           ]
       }
    },
    data(){
        return {
            current:0,
        }
    },
    methods:{
        handlePrevEvent(){
            if(this.current > 0){
                --this.current;
            }
        },
        handleNextEvent(){
            if(this.list.length - 4 > this.current){
                ++this.current;
            }
        }
    }

};
</script>

<style scoped lang="less">
.committee-list{
    position: relative;
    overflow: hidden;
    .swiper-content{
        flex-wrap:nowrap;
        transition: all 0.15s linear;
    }
    .btn-direction{
        cursor: pointer;
        position: absolute;
        top:50%;
        width:30px;
        height:45px;
        line-height: 45px;
        text-align: center;
        margin-top:-22px;
        background-color: @theme;
        color:#fff;
        border-radius: 30px;
        font-size:16px;
        z-index:2;
        &.disabled{
            opacity: 0.3;
        }
        &.med-left{
            left:0;
        }
        &.med-right{
            right:0;
        }
    }
}
@media screen and (max-width:768px){
    .committee-list{
        .scroll-left{
            display: none;
        }
        .swiper-content{
            transform:translate()!important;
            overflow-x: scroll;
        }
    }
}
</style>
